<template>
  <v-form v-model="validateUser">
    <v-container
      style="background-color:#fafafa; max-width:650px;"
      rounded
    >
      <v-tabs align-with-title fixed-tabs v-model="tab">
        <v-tabs-slider color="blue"></v-tabs-slider>

        <v-tab v-for="item in tabs" :key="item.id">{{ item.name }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="'warranty'">
          <v-container
            class="pa-9"
            rounded
          >
            <v-row>
              <v-col cols="12" class="text-center">
                <p class="text-left">
                  Llena el siguiente formulario y activa tu garantía, una vez que termines el registro obtendrás 3 meses de extensión de garantía.
                </p>
                <h1>
                  Formato de Garantía
                </h1>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Comercial"
                  :rules="requiredRules"
                  required
                  v-model="garantia.distribuidor"
                  prepend-icon="mdi-domain"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="garantia.telefono"
                  label="Teléfono"
                  :rules="requiredRules"
                  required
                  type="number"
                  prepend-icon="mdi-cellphone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Email"
                  v-model="garantia.email"
                  :rules="emailRules"
                  required
                  prepend-icon="mdi-email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="garantia.numeroSerie"
                  label="Número de serie"
                  :rules="requiredRules"
                  required
                  prepend-icon="mdi-motorbike"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Motor"
                  v-model="garantia.motor"
                  :rules="requiredRules"
                  required
                  prepend-icon="mdi-motorbike"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="modelos"
                  v-model="garantia.modelo"
                  prepend-icon="mdi-ticket-percent"
                  label="Modelo"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="garantia.date"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="garantia.date"
                      label="Fecha de venta"
                      prepend-icon="mdi-calendar"
                      :rules="requiredRules"
                      required
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="garantia.date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(garantia.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="garantia.kilometraje"
                  label="Kilometraje"
                  :rules="requiredRules"
                  required
                  prepend-icon="mdi-motorbike"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="garantia.numCupon"
                  label="Número de cupón"
                  :rules="requiredRules"
                  required
                  prepend-icon="mdi-counter"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-container>
                  <v-row>
                    <v-col cols="10" class="pa-0">
                      <v-text-field
                        v-model="garantia.numParteUno[0]"
                        label="Número de parte 1"
                        :rules="requiredRules"
                        required
                        prepend-icon="mdi-ticket-percent"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-icon color="primary" @click="numeroParte++">mdi-plus</v-icon>
                      <v-icon
                        color="error"
                        @click="numeroParte === 0 ? (numeroParte = 0) : numeroParte--"
                        >mdi-minus</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12" sm="6" v-for="index in numeroParte" v-bind:key="index">
                <v-text-field
                  v-model="garantia.numParteUno[index]"
                  :label="'Número de parte ' + (index + 1)"
                  :rules="requiredRules"
                  required
                  prepend-icon="mdi-ticket-percent"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="garantia.descripcion"
                  label="Descripción"
                  :rules="requiredRules"
                  required
                  prepend-icon="mdi-file-chart"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Cantidad"
                  v-model="garantia.cantidad"
                  :rules="requiredRules"
                  required
                  prepend-icon="mdi-ticket-percent"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Horas de mano de obra"
                  v-model="garantia.horasObra"
                  :rules="requiredRules"
                  type="number"
                  required
                  prepend-icon="mdi-wrench"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="ingieriosServicio"
                  v-model="garantia.ingenieroServicio"
                  :rules="requiredRules"
                  prepend-icon="mdi-account-hard-hat"
                  label="Ingeniero de servicio"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="tipoFalla"
                  v-model="garantia.tipoFalla"
                  :rules="requiredRules"
                  prepend-icon="mdi-message-alert"
                  label="Tipo de falla"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="garantia.reporteFalla"
                  label="Reporte de la falla"
                  :rules="requiredRules"
                  required
                  prepend-icon="mdi-message"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  chips
                  type="file"
                  v-model="file"
                  ref="file"
                  label="Imagenes principales de la falla"
                  class="pt-4"
                  outlined
                  dense
                  accept="image/*"
                  prepend-icon="mdi-image"
                  multiple
                ></v-file-input>
                <h4 class="grey--text mb-2 pl-4">
                  *Puede seleccionar varias imagenes
                </h4>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  large
                  block
                  rounded
                  :disable="disable"
                  :loading="cargando"
                  class="white--text"
                  @click="insertarGarantia"
                  >Enviar</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item :key="'spare-parts'">
          <SpareParts />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-form>
</template>

<script>
import PostService from "../PostService";
import rules from "../../mixins/rules";
import SpareParts from "../components/SpareParts.vue";
export default {
  name: "garantias",
  components: { SpareParts },
  mixins: [rules],
  data: () => ({
    file: null,
    menu: false,
    numeroParte: 0,
    garantia: {
      distribuidor: "",
      date: null,
      telefono: "",
      email: "",
      numeroSerie: "",
      motor: "",
      modelo: "",
      kilometraje: "",
      numCupon: "",
      numParteUno: [],
      descripcion: "",
      cantidad: "",
      horasObra: null,
      ingenieroServicio: "",
      tipoFalla: "",
      imagen: "",
      reporteFalla: ""
    },
    modelos: [
      "DOMINAR D 400 ABS-EXP",
      "DOMINAR 400 UG",
      "BOXER 150 BM",
      "PLATINA 125",
      "PLATINA 100",
      "PULSAR 125 NS",
      "PULSAR 150 Neon",
      "PULSAR 220 Neon",
      "PULSAR NS 200",
      "PULSAR 200 FI ABS",
      "PULSAR 200 RS",
      "PULSAR NS 160 TD",
      "AVENGER 220 CRUISE",
      "DOMINAR 250 UG"
    ],
    ingieriosServicio: [
      "Everardo Alferez",
      "Ignacio Villeda",
      "Jorge Orozco",
      "Norberto Chao",
      "Adrían López",
      "Javier Calvo"
    ],
    tipoFalla: [
      "Motor",
      "Eléctrico",
      "Chasis",
      "Daño en tienda",
      "Daño en transporte",
      "Daño en planta"
    ],
    imagenes: [],
    validateUser: false,
    cargando: false,
    disable: false,
    tabs: [
      { id: "warranty", name: "Activa tu garantía" },
      { id: "spare-parts", name: "Refacciones" }
    ],
    tab: 0
  }),
  mounted() {
    let inputs = document.querySelectorAll(".v-file-input input");
    [...inputs].forEach(input => {
      input.remove();
    });
  },
  methods: {
    async insertarGarantia() {
      try {
        this.disable = true;
        this.cargando = true;
        if (!this.validateUser) {
          this.cargando = false;
          this.disable = false;
          return this.$store.dispatch("setSnackBarProps", {
            color: "error",
            mensaje: "Algún campo falta. Por favor intenta de nuevo.",
            mostrar: true
          });
        }
        const numeroPartes = [];
        for (let i = 0; i <= this.numeroParte; i++) {
          numeroPartes.push(this.garantia.numParteUno[i]);
        }
        if (this.file != null) {
          for (const imagen of this.file) {
            const urlImagen = await this.subirImagen(imagen);
            this.imagenes.push(urlImagen.data);
          }
        }
        this.imagenes != null
          ? (this.garantia.imagen = this.imagenes.toString())
          : "undefined";
        this.garantia.numParteUno = numeroPartes.toString();
        const respuesta = await PostService.postGarantias(this.garantia);
        this.cargando = false;
        this.$store.dispatch("setSnackBarProps", {
          color: "success",
          mensaje: respuesta.data,
          mostrar: true
        });
        this.$router.push("/exito");
      } catch (error) {
        this.$store.dispatch("setSnackBarProps", {
          color: "error",
          mensaje: "Ha ocurrido un error. Intente de nuevo."
        });
      }
    },
    async subirImagen(file) {
      const formDataUpload = new FormData();
      formDataUpload.append("file", file);
      const respuesta = await PostService.subirImagen(formDataUpload);
      return respuesta;
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
h1 {
  font-family: "Open Sans", sans-serif;
  color: rgb(59, 59, 59);
  font-size: 38px;
}
h2 {
  font-family: "Open Sans", sans-serif;
  color: rgb(92, 92, 92);
  font-size: 17px;
}
</style>
